<template>
  <div class="<md:h-screen">
    <div class="relative <md:h-full">
      <base-picture
        :src="{
          sm: 'https://assets.thenorthface.eu/image/upload/v1723123860/240328-TNF-ss24-404-lp-m.jpg',
          md: 'https://assets.thenorthface.eu/image/upload/v1723123860/240328-TNF-ss24-404-lp-m.jpg',
          lg: 'https://assets.thenorthface.eu/image/upload/v1723186443/240328-TNF-SS24-404-LP-RIGHT.jpg',
        }"
        alt=""
        class="full"
        fit="cover"
        :props-img="{ class: 'w-full', style: 'object-position: top right', role: 'presentation' }"
      />
      <div class="absolute-0 <lg:flex">
        <div class="px-4 py-16 md:max-w-2/3">
          <h1 class="display-2">
            {{ $t.maintenance.ERR403.title }}
          </h1>
          <p class="mt-4 lg:(mt-6 text-md)">
            {{ $t.maintenance.ERR403.message }}
          </p>
          <vf-button class="mt-8 <md:w-full" @click="clearError({ redirect: '/' })">
            {{ $t.maintenance.ERR4xx.cta }}
          </vf-button>
        </div>
      </div>
    </div>
  </div>
</template>
